import React, { useState, useRef } from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Link } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const FAQs = ({ faqs }) => {
    const sectionRef = useRef(null);
      const titleRef = useRef(null);
          const subtitleRef = useRef(null);
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useGSAP(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            toggleActions: "play none none none"
          },
        });
        tl.from(titleRef.current, {
          opacity: 0,
          y: 30,
          duration: 0.3,
          ease: "power2.out"
        })
          .from(subtitleRef.current, {
            opacity: 0,
            y: 20,
            duration: 0.3,
            ease: "power2.out"
          })
      });

    return (
        <Box className="faqs-section" ref={sectionRef}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h3" gutterBottom className="faqs-title" ref={titleRef}>
                        {t("FAQS_COMPONENT.TITLE")}
                    </Typography>
                    <Typography variant="body1" className="faqs-description" ref={subtitleRef}>
                        {t("FAQS_COMPONENT.DESCRIPTION")}{" "}
                        <Link href="mailto:support@intelswift.com" className="faqs-email">
                            {t("FAQS_COMPONENT.EMAIL")}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    {faqs.map((faq, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            className="faqs-accordion"
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="faqs-question">
                                {faq.question}
                            </AccordionSummary>
                            <AccordionDetails className="faqs-answer">
                                {faq.answer}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default FAQs;
