// --- Third Party Libraries ---
import React, { useMemo, useRef } from "react";
import { Typography, Box, Grid, Card, Button, Stack, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaEarthEurope } from "react-icons/fa6";
import { RiWechatLine } from "react-icons/ri";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";

import {
  SIGN_UP_URL,
  GET_DEMO_URL
} from "./../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

// --- DistributeContent Component ---
const DistributeContent = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);
  const noCardRef = useRef(null);

  const features = useMemo(() => [
    {
      title: t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.FEATURES.INTERNAL_DOCS.TITLE"),
      description: t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.FEATURES.INTERNAL_DOCS.DESCRIPTION"),
      icon: <IoDocumentTextOutline className="distribute-content-icon" />,
      bgColor: "rgba(64, 117, 221, 1)"
    },
    {
      title: t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.FEATURES.WEB_PAGES.TITLE"),
      description: t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.FEATURES.WEB_PAGES.DESCRIPTION"),
      icon: <FaEarthEurope className="distribute-content-icon" />,
      bgColor: "rgba(148, 87, 255, 1)"
    },
    {
      title: t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.FEATURES.EXTERNAL_COMM.TITLE"),
      description: t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.FEATURES.EXTERNAL_COMM.DESCRIPTION"),
      icon: <RiWechatLine className="distribute-content-icon" />,
      bgColor: "rgba(223, 119, 156, 1)"
    }
  ], [t]);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%",
        toggleActions: "play none none none"
      },
    });
    tl.from(titleRef.current, {
      opacity: 0,
      y: 30,
      duration: 0.3,
      ease: "power2.out"
    })
      .from(cardsRef.current, {
        opacity: 0,
        y: 30,
        duration: 0.5,
        stagger: 0.2,
        ease: "power1.out",
      })
      .fromTo(buttonRef.current, {
        opacity: 0,
        y: 10,
        scale: 0.95,
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.2,
        ease: "power2.out",
      })
      .fromTo(noCardRef.current, {
        opacity: 0,
        y: 10,
        scale: 0.95,
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.2,
        ease: "power2.out",
      })
  });

  return (
    <Box className="distribute-content-container" ref={sectionRef}>
      <Typography variant="h3" ref={titleRef}>
        {t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.TITLE")}
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            className="distribute-content-card"
            ref={(el) => (cardsRef.current[index] = el)}
          >
            <motion.div whileHover={{ scale: 1.05 }} className="distribute-content-card-content">
              <Box
                className="distribute-content-circle"
                style={{ background: feature.bgColor }}
              >
                {feature.icon}
              </Box>
              <Typography variant="h5" className="distribute-content-title">
                {feature.title}
              </Typography>
              <Typography variant="body1" className="distribute-content-description">
                {feature.description}
              </Typography>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <Stack spacing={1} className="distribute-content-stack">
        <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
          <Button target="_blank" ref={buttonRef}>
            {t("COMMON.START_TRIAL_BUTTON")}
          </Button>
        </Link>
        <Typography variant="subtitle1" ref={noCardRef}>
          {t("KNOWLEDGE_BASE_PAGE.DISTRIBUTE_SECTION.NO_CREDIT_CARD")}
        </Typography>
      </Stack>
    </Box>
  );
};

export default DistributeContent;
