import React, {useRef} from "react";
import { Typography, Button, Grid, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { partnershipImages } from "../../utils/imageUtils";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

const Hero = () => {
  const { t } = useTranslation();
    const titleRef = useRef(null);
    const descRef1 = useRef(null);
    const descRef2 = useRef(null);
    const buttonRef = useRef(null);
    const imageRef = useRef(null);

      useGSAP(() => {
        const entrance = gsap.timeline();
        entrance
          .fromTo(titleRef.current, {
            opacity: 0,
            y: 60,
            scale: 0.95,
          }, {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.5,
            ease: "power2.out",
          })
          .fromTo(descRef1.current, {
            opacity: 0,
            y: 10,
            scale: 0.95,
          }, {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.2,
            ease: "power2.out",
          })
          .fromTo(descRef2.current, {
            opacity: 0,
            y: 10,
            scale: 0.95,
          }, {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.2,
            ease: "power2.out",
          })
          .fromTo(buttonRef.current, {
            opacity: 0,
            y: 10,
            scale: 0.95,
          }, {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.2,
            ease: "power2.out",
          })
          .fromTo(imageRef.current, {
            opacity: 0,
            y: 0,
            scale: 0.95,
          }, {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 1.0,
            ease: "power2.out",
          })
      });

  return (
    <Grid container spacing={4} className="partnership-hero-section">
      <Grid item xs={12} md={5} className="partnership-hero-left">
        <Typography variant="h1" ref={titleRef}>
          {t("PARTNERSHIP_PAGE.HERO_SECTION.TITLE")}
        </Typography>
        <Typography variant="subtitle1" ref={descRef1}>
          {t("PARTNERSHIP_PAGE.HERO_SECTION.SUBTITLE_1")}
        </Typography>
        <Typography variant="subtitle1" ref={descRef2}>
          {t("PARTNERSHIP_PAGE.HERO_SECTION.SUBTITLE_2")}
        </Typography>
        <Link
          component={RouterLink}
          to={"https://intelswift.firstpromoter.com"}
          target="_blank"
          underline="none"
        >
          <Button className="free-button" target="_blank" ref={buttonRef}>
            {t("PARTNERSHIP_PAGE.HERO_SECTION.CTA")}
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} md={7}>
        <img
          src={partnershipImages.partnership_img_1}
          alt={t("PARTNERSHIP_PAGE.HERO_SECTION.HERO_IMAGE_ALT")}
          ref={imageRef}
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
