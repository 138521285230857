import React, {useRef} from "react";
import { Typography, Button, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { workflowsImages } from "../../utils/imageUtils";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import {
    SIGN_UP_URL
  } from "./../../utils/constants";

const Hero = () => {
    const { t } = useTranslation();
      const titleRef = useRef(null);
      const descRef = useRef(null);
      const buttonRef = useRef(null);
      const noCardRef = useRef(null);
      const imageRef = useRef(null);

        useGSAP(() => {
          const entrance = gsap.timeline();
          entrance
            .fromTo(titleRef.current, {
              opacity: 0,
              y: 60,
              scale: 0.95,
            }, {
              opacity: 1,
              y: 0,
              scale: 1,
              duration: 0.5,
              ease: "power2.out",
            })
            .fromTo(descRef.current, {
              opacity: 0,
              y: 10,
              scale: 0.95,
            }, {
              opacity: 1,
              y: 0,
              scale: 1,
              duration: 0.2,
              ease: "power2.out",
            })
            .fromTo(buttonRef.current, {
              opacity: 0,
              y: 10,
              scale: 0.95,
            }, {
              opacity: 1,
              y: 0,
              scale: 1,
              duration: 0.2,
              ease: "power2.out",
            })
            .fromTo(noCardRef.current, {
              opacity: 0,
              y: 10,
              scale: 0.95,
            }, {
              opacity: 1,
              y: 0,
              scale: 1,
              duration: 0.2,
              ease: "power2.out",
            })
            .fromTo(imageRef.current, {
              opacity: 0,
              y: 0,
              scale: 0.95,
            }, {
              opacity: 1,
              y: 0,
              scale: 1,
              duration: 1.0,
              ease: "power2.out",
            })
        });

    return (
        <Box className="workflows-hero-section">
            <Box className="workflows-hero-content">
                <Typography variant="h1" ref={titleRef}>
                {t("WORKFLOWS_PAGE.HERO_SECTION.TITLE")}
                </Typography>
                <Typography variant="h3" ref={descRef}>
                {t("WORKFLOWS_PAGE.HERO_SECTION.DESCRIPTION")}               
                 </Typography>
                 <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                    <Button variant="contained" className="live-chat-features-demo-button" ref={buttonRef}>
                    {t("COMMON.SIGN_UP_FREE_BUTTON")}
                    </Button>
                </Link>
                <Typography variant="subtitle1" ref={noCardRef}>
                {t("WORKFLOWS_PAGE.HERO_SECTION.NO_CREDIT_CARD")}
                </Typography>
                <img
                    src={workflowsImages.workflows_img_1}
                    alt="Intelswift - Improve Support With AI Customer Service Workflow Automation"
                    className="workflows-image"
                    ref={imageRef}
                />
            </Box>
        </Box>
    );
}

export default Hero;
