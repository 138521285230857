// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

// --- Image Utilities ---
import { learnMoreImages } from "../../utils/imageUtils";

gsap.registerPlugin(ScrollTrigger);

// --- LearnMore Component ---
const LearnMore = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sectionRef = useRef(null);
  const imageRefs = useRef([]);
  const textRefs = useRef([]);

  useGSAP(() => {
    imageRefs.current.forEach((img, i) => {
      gsap.from(img, {
        opacity: 0,
        x: i % 2 === 0 ? -100 : 100,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: img,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });
    });

    textRefs.current.forEach((text) => {
      gsap.from(text, {
        opacity: 0,
        y: 30,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: text,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });
    });
  }, []);

  const sections = [
    {
      titleKey: "AI_AGENTS",
      route: "/ai-agents",
      image: learnMoreImages.ai_agents_img,
      reverse: false,
    },
    {
      titleKey: "AI_COPILOT",
      route: "/ai-copilot",
      image: learnMoreImages.ai_copilot_img,
      reverse: true,
    },
    {
      titleKey: "LIVE_CHAT",
      route: "/ai-helpdesk",
      image: learnMoreImages.live_chat_img,
      reverse: false,
    },
    {
      titleKey: "WORKFLOWS",
      route: "/workflows",
      image: learnMoreImages.workflows_img,
      reverse: true,
    },
    {
      titleKey: "KNOWLEDGE_BASE",
      route: "/ai-knowledge-base",
      image: learnMoreImages.knowledge_base_img,
      reverse: false,
    },
    {
      titleKey: "WORKSPACE",
      route: null, // No button
      image: learnMoreImages.workspace_img,
      reverse: true,
    },
  ];

  return (
    <Box ref={sectionRef}>
      {sections.map((sec, index) => (
        <Box
          key={sec.titleKey}
          className="learn-more-container"
        >
          <Grid
            container
            spacing={4}
            className="learn-more-grid-container"
            direction={sec.reverse ? "row-reverse" : "row"}
          >
            <Grid
              item
              xs={12}
              md={6}
              className="learn-more-image-container"
              ref={(el) => (imageRefs.current[index] = el)}
            >
              <img
                className="learn-more-image"
                src={sec.image}
                alt={t(`HOME_PAGE.LEARN_MORE.${sec.titleKey}.${sec.titleKey}_ALT`)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="learn-more-left"
              ref={(el) => (textRefs.current[index] = el)}
            >
              <Typography variant="h3">
                {t(`HOME_PAGE.LEARN_MORE.${sec.titleKey}.TITLE`)}
              </Typography>
              <Typography variant="subtitle1">
                {t(`HOME_PAGE.LEARN_MORE.${sec.titleKey}.DESCRIPTION`)}
              </Typography>
              {sec.route && (
                <Button
                  variant="contained"
                  onClick={() => navigate(sec.route)}
                >
                  {t("COMMON.LEARN_MORE_BUTTON")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default LearnMore;
