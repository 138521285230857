import React, { useRef, useEffect, useState } from "react";
import { Box, Typography, Button, Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./index.css";
import {
  SIGN_UP_URL,
  GET_DEMO_URL
} from "./../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

const videoUrls = [
  "https://www.youtube.com/embed/I-j_SMn8RRc?controls=0&modestbranding=1&rel=0&iv_load_policy=3",
  "https://www.youtube.com/embed/mFuyX1XgJFg?controls=0&modestbranding=1&rel=0&iv_load_policy=3",
  "https://www.youtube.com/embed/GDlkCkcIqTs?controls=0&modestbranding=1&rel=0&iv_load_policy=3"
];

const Planet = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const buttonRef = useRef(null);
  const videoWrapperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%",
        toggleActions: "play none none none"
      },
    });
    tl.from(titleRef.current, {
      opacity: 0,
      y: 30,
      duration: 0.3,
      ease: "power2.out"
    })
      .from(subtitleRef.current, {
        opacity: 0,
        y: 20,
        duration: 0.3,
        ease: "power2.out"
      })
      .from(buttonRef.current, {
        opacity: 0,
        y: 40,
        duration: 0.3,
        ease: "power2.out"
      })
  });

  useGSAP(() => {
    const tl = gsap.timeline();
    tl.to(videoWrapperRef.current, {
      opacity: 0,
      x: 10,
      duration: 0.3,
      ease: "power2.out",
      onComplete: () => {
        tl.set(videoWrapperRef.current, { x: -10 });
        tl.to(videoWrapperRef.current, {
          opacity: 1,
          y: 0,
          duration: 0.4,
          ease: "power2.out"
        });
      }
    });
  }, { dependencies: [currentIndex] });

  return (
    <Box className="planet-component" ref={sectionRef}>
      <Typography variant="h3" ref={titleRef}>
        {t("PLANET_COMPONENT.TITLE")}
      </Typography>
      <Typography variant="subtitle1" ref={subtitleRef}>
        {t("PLANET_COMPONENT.DESCRIPTION")}
      </Typography>
      <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
        <Button variant="contained" className="planet-component-button" ref={buttonRef}>
          {t("COMMON.LEARN_MORE_BUTTON")}
        </Button>
      </Link>
      <Box className="video-wrapper" ref={videoWrapperRef}>
        <iframe
          key={currentIndex}
          src={videoUrls[currentIndex]}
          title={`YouTube video ${currentIndex + 1}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
      {videoUrls.length > 1 && (
        <Stack direction="row" spacing={1}>
          {videoUrls.map((_, index) => (
            <Box
              key={index}
              onClick={() => setCurrentIndex(index)}
              sx={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: currentIndex === index ? "#6B737E" : "#ebedf4",
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default Planet;
