import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

gsap.registerPlugin(ScrollTrigger);

function ScrollTriggerFixer() {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 250);
  }, [location]);

  return null;
}

export default ScrollTriggerFixer;