// --- Third Party Libraries ---
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Box, Button, Typography, Stack, Popper, List, ListItem, ListItemButton, ListItemText, Divider, Drawer } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { FiMenu, FiX } from "react-icons/fi";
import "./index.css";

// --- Image Utilities ---
import { headerImages } from "../../utils/imageUtils";

// --- Constants ---
import {
  SIGN_UP_URL,
  GET_DEMO_URL,
} from "../../utils/constants";

// --- Header Component ---
const Header = () => {
  // --- Hooks and State ---
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSolutions, setAnchorElSolutions] = useState(null);
  const [anchorElResources, setAnchorElResources] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState({ top: false });
  const [isProductsExpanded, setIsProductsExpanded] = useState(false);
  const [isSolutionsExpanded, setIsSolutionsExpanded] = useState(false);
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);

  // --- Product Menu Data ---
  const PRODUCTS = [
    {
      name: t("HEADER.PRODUCTS_MENU.AI_CUSTOMER_SERVICE_AGENT.NAME"),
      description: t("HEADER.PRODUCTS_MENU.AI_CUSTOMER_SERVICE_AGENT.DESCRIPTION"),
      image: headerImages.ai_customer_service_agent_img,
      path: "/ai-agents"
    },
    {
      name: t("HEADER.PRODUCTS_MENU.SWIFT_AI_COPILOT.NAME"),
      description: t("HEADER.PRODUCTS_MENU.SWIFT_AI_COPILOT.DESCRIPTION"),
      image: headerImages.swift_ai_copilot_img,
      path: "/ai-copilot"
    },
    {
      name: t("HEADER.PRODUCTS_MENU.LIVE_CHAT.NAME"),
      description: t("HEADER.PRODUCTS_MENU.LIVE_CHAT.DESCRIPTION"),
      image: headerImages.live_chat_img,
      path: "/ai-helpdesk"
    },
    {
      name: t("HEADER.PRODUCTS_MENU.WORKFLOWS.NAME"),
      description: t("HEADER.PRODUCTS_MENU.WORKFLOWS.DESCRIPTION"),
      image: headerImages.workflows_img,
      path: "/workflows"
    },
    {
      name: t("HEADER.PRODUCTS_MENU.AI_KNOWLEDGE_BASE.NAME"),
      description: t("HEADER.PRODUCTS_MENU.AI_KNOWLEDGE_BASE.DESCRIPTION"),
      image: headerImages.ai_knowledge_base,
      path: "/ai-knowledge-base"
    },
    // {
    //   name: t("HEADER.PRODUCTS_MENU.ADVANCED_AI_ANALYTICS.NAME"),
    //   description: t("HEADER.PRODUCTS_MENU.ADVANCED_AI_ANALYTICS.DESCRIPTION"),
    //   image: headerImages.advanced_ai_analytics_img,
    //   path: "/ai-analytics"
    // },
    
  ]

  const [selectedProduct, setSelectedProduct] = useState(PRODUCTS[0]);

  const SOLUTIONS = {
    use_cases: [
      { name: t("HEADER.SOLUTIONS_MENU.CUSTOMER_SERVICE"), path: "" },
      { name: t("HEADER.SOLUTIONS_MENU.SALES_DEPARTMENT"), path: "" },
      { name: t("HEADER.SOLUTIONS_MENU.MARKETING_DEPARTMENT"), path: "" },
    ],
    industries: [
      { name: t("HEADER.SOLUTIONS_MENU.CUSTOMER_SUPPORT_BPO"), path: "" },
      { name: t("HEADER.SOLUTIONS_MENU.SAAS_SOLUTIONS"), path: "" },
      { name: t("HEADER.SOLUTIONS_MENU.MARKETING_AGENCY"), path: "" },
    ],
  };

  const RESOURCES = [
    { name: t("HEADER.RESOURCES_MENU.BLOG"), path: "/blog" },
    { name: t("HEADER.RESOURCES_MENU.ABOUT"), path: "/about" },
  ]

  // --- Event Handlers ---
  const handleOpenProductsMenu = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseProductsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSolutionsMenu = (event) => {
    setAnchorElSolutions(anchorElSolutions ? null : event.currentTarget);
  };

  const handleCloseSolutionsMenu = () => {
    setAnchorElSolutions(null);
  };

  const handleOpenResourcesMenu = (event) => {
    setAnchorElResources(anchorElResources ? null : event.currentTarget);
  };

  const handleCloseResourcesMenu = () => {
    setAnchorElResources(null);
  };

  const toggleMobileMenu = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsMobileMenuOpen({ ...isMobileMenuOpen, [anchor]: open });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleCloseProductsMenu();
      }
    };
    if (anchorEl) {
      window.addEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorElSolutions && !anchorElSolutions.contains(event.target)) {
        handleCloseSolutionsMenu();
      }
    };
    if (anchorElSolutions) {
      window.addEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [anchorElSolutions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorElResources && !anchorElResources.contains(event.target)) {
        handleCloseResourcesMenu();
      }
    };
    if (anchorElResources) {
      window.addEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [anchorElResources]);

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleMobileMenu(anchor, false)}
      onKeyDown={toggleMobileMenu(anchor, false)}
      className="drawer-wrapper"
    >
      <List>
        <ListItem sx={{ pl: 0, pt: 0, pb: 0 }}>
          <ListItemButton onClick={(e) => {
            e.stopPropagation();
            setIsProductsExpanded(!isProductsExpanded);
            if (!isProductsExpanded) {
              setIsSolutionsExpanded(false);
              setIsResourcesExpanded(false);
            }
          }}>
            <ListItemText className="drawer-list-item-name" primary={t("HEADER.PRODUCTS")} />
            <IoIosArrowDown
              style={{
                transform: isProductsExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
                height: "20px",
                width: "20px",
                color: "white"
              }}
            />
          </ListItemButton>
        </ListItem>
        {isProductsExpanded && PRODUCTS.map((product, index) => (
          <ListItem key={index}>
            <ListItemButton component={Link} to={product.path}>
              <ListItemText className="drawer-item-name" primary={product.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem sx={{ pl: 0, pt: 0, pb: 0 }}>
          <ListItemButton onClick={(e) => {
            e.stopPropagation();
            setIsSolutionsExpanded(!isSolutionsExpanded);
            if (!isSolutionsExpanded) {
              setIsProductsExpanded(false);
              setIsResourcesExpanded(false);
            }
          }}>
            <ListItemText className="drawer-list-item-name" primary={t("HEADER.SOLUTIONS")} />
            <IoIosArrowDown
              style={{
                transform: isSolutionsExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
                height: "20px",
                width: "20px",
                color: "white"
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* {isSolutionsExpanded && (
          <>
            {SOLUTIONS.use_cases.map((useCase, index) => (
              <ListItem key={index}>
                <ListItemButton onClick={() => navigate(useCase.path)}>
                  <ListItemText className="drawer-item-name" primary={useCase.name} />
                </ListItemButton>
              </ListItem>
            ))}
            {SOLUTIONS.industries.map((industry, index) => (
              <ListItem key={index}>
                <ListItemButton onClick={() => navigate(industry.path)}>
                  <ListItemText className="drawer-item-name" primary={industry.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )} */}
        <ListItem>
          <ListItemButton sx={{ pl: 0, pt: 0, pb: 0 }} component={Link} to={"/pricing"}>
            <ListItemText className="drawer-item-name" primary={t("HEADER.PRICING")} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton sx={{ pl: 0, pt: 0, pb: 0 }} component={Link} to={"/partnership"}>
            <ListItemText className="drawer-item-name" primary={t("HEADER.PARTNERS")} />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ pl: 0, pt: 0, pb: 0 }}>
          <ListItemButton onClick={(e) => {
            e.stopPropagation();
            setIsResourcesExpanded(!isResourcesExpanded);
            if (!isResourcesExpanded) {
              setIsProductsExpanded(false);
              setIsSolutionsExpanded(false);
            }
          }}>
            <ListItemText className="drawer-list-item-name" primary={t("HEADER.RESOURCES")} />
            <IoIosArrowDown
              style={{
                transform: isResourcesExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
                height: "20px",
                width: "20px",
                color: "white"
              }}
            />
          </ListItemButton>
        </ListItem>
        {isResourcesExpanded && RESOURCES.map((resource, index) => (
          <ListItem key={index}>
            <ListItemButton component={Link} to={resource.path}>
              <ListItemText className="drawer-item-name" primary={resource.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Stack spacing={2} direction="column">
          <Button
            className="login-button-drawer"
            variant="text"
            href={SIGN_UP_URL} target="_blank"
          >
            {t("HEADER.LOGIN")}
          </Button>
          <Button className="free-trial-button-drawer" href={SIGN_UP_URL} target="_blank">
            {t("COMMON.FREE_TRIAL_BUTTON")}
          </Button>
          <Button className="demo-button-drawer" href={GET_DEMO_URL} target="_blank">
            {t("COMMON.GET_A_DEMO_BUTTON")}
          </Button>
        </Stack>
      </List>
    </Box>
  );


  // --- Render Component ---
  return (
    <AppBar className="header-wrapper">
      <Toolbar className="header-container">
        <Box className="header-middle-section-wrapper">
          <Link to="/">
            <img className="header-logo" src={headerImages.logo} alt="Logo" />
          </Link>
          <Stack className="header-middle-section" direction="row">
            <Button endIcon={<IoIosArrowDown />} onClick={handleOpenProductsMenu}>
              {t("HEADER.PRODUCTS")}
            </Button>
            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseProductsMenu}
              className="products-menu-popover"
            >
              <Box className="products-menu-container">
                <List className="products-list">
                  {PRODUCTS.map((product, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        className={`product-item ${selectedProduct.name === product.name ? "active" : ""}`}
                        component={Link} to={product.path}
                        onMouseEnter={() => setSelectedProduct(product)}
                      >
                        <ListItemText primary={product.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                <Box
                  className="product-preview"
                  component={Link} to={selectedProduct.path}
                  sx={{ cursor: "pointer" }}
                >
                  <img src={selectedProduct.image} alt={selectedProduct.name} className="product-image" />
                  <Typography variant="h6" className="header-product-title">
                    {selectedProduct.name}
                  </Typography>
                  <Typography variant="body2" className="header-product-description">
                    {selectedProduct.description}
                  </Typography>
                </Box>
              </Box>
            </Popper>
            {/* <Button endIcon={<IoIosArrowDown />} onClick={handleOpenSolutionsMenu}>
              {t("HEADER.SOLUTIONS")}
            </Button>
            <Popper
              open={Boolean(anchorElSolutions)}
              anchorEl={anchorElSolutions}
              className="solutions-menu-popover"
            >
              <Box className="solutions-menu-container">
                <Box className="solutions-column">
                  <Typography className="solutions-title">{t("HEADER.SOLUTIONS_MENU.USE_CASES")}</Typography>
                  <List className="solutions-list">
                    {SOLUTIONS.use_cases.map((solution, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton className="solutions-item" onClick={() => navigate(solution.path)}>
                          <ListItemText primary={solution.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box className="solutions-column">
                  <Typography className="solutions-title">{t("HEADER.SOLUTIONS_MENU.INDUSTRIES")}</Typography>
                  <List className="solutions-list">
                    {SOLUTIONS.industries.map((industry, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton className="solutions-item" onClick={() => navigate(industry.path)}>
                          <ListItemText primary={industry.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Popper> */}
            <Button component={Link} to={"/pricing"}>
              {t("HEADER.PRICING")}
            </Button>
            <Button component={Link} to={"/partnership"}>
            {t("HEADER.PARTNERS")}
            </Button>
            <Button endIcon={<IoIosArrowDown />} onClick={handleOpenResourcesMenu}>
              {t("HEADER.RESOURCES")}
            </Button>
            <Popper
              open={Boolean(anchorElResources)}
              anchorEl={anchorElResources}
              className="resources-menu-popover"
              placement="bottom-start"
            >
              <Box className="resources-menu-container">
                <List className="resources-list">
                  {RESOURCES.map((resource, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton className="resources-item" component={Link} to={resource.path}>
                        <ListItemText primary={resource.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Popper>
          </Stack>
          <Stack spacing={2} direction="row">
            <Button
              className="login-button"
              variant="text"
              href={SIGN_UP_URL} target="_blank"
            >
              {t("HEADER.LOGIN")}
            </Button>
            <Button className="free-trial-button" href={SIGN_UP_URL} target="_blank">
              {t("COMMON.FREE_TRIAL_BUTTON")}
            </Button>
            <Button className="demo-button" href={GET_DEMO_URL} target="_blank">
              {t("COMMON.GET_A_DEMO_BUTTON")}
            </Button>
          </Stack>
          <Box className="burger-menu">
            {isMobileMenuOpen.top ? (
              <FiX size={24} onClick={() => toggleMobileMenu('top', false)()} />
            ) : (
              <FiMenu size={24} onClick={() => toggleMobileMenu('top', true)()} />
            )}
          </Box>
          <Drawer
            anchor="top"
            open={isMobileMenuOpen.top}
            onClose={toggleMobileMenu('top', false)}
            PaperProps={{
              sx: {
                top: '85px',
              },
            }}
          >
            {list('top')}
          </Drawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;