import React, { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
// --- CSS ---
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const CallToAction = ({ title, subtitle, showButton }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const buttonRef = useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%",
        toggleActions: "play none none none"
      },
    });
    tl.from(titleRef.current, {
      opacity: 0,
      y: 30,
      duration: 0.3,
      ease: "power2.out"
    })
    .from(subtitleRef.current, {
      opacity: 0,
      y: 20,
      duration: 0.3,
      ease: "power2.out"
  })
      .from(buttonRef.current, {
        opacity: 0,
        y: 40,
        duration: 0.3,
        ease: "power2.out"
      })
  });

  return (
    <Box className="cta-container" ref={sectionRef}>
      <div className="circle1"></div>
      <div className="circle2"></div>
      <Typography variant="h3" ref={titleRef}>
        {title}
      </Typography>
      <Typography variant="subtitle1" ref={subtitleRef}>{subtitle}</Typography>
      {showButton && (
        <Button ref={buttonRef} onClick={() => { navigate('/pricing') }}>
          {t("COMMON.SEE_PRICING_BUTTON")}
        </Button>
      )}
    </Box>
  );
};

export default CallToAction;