// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Box, Grid, Button, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import {
  SIGN_UP_URL,
  GET_DEMO_URL
} from "./../../utils/constants";

// --- Image Utilities ---
import { liveChatImages } from "../../utils/imageUtils";

gsap.registerPlugin(ScrollTrigger);

// --- Features Component ---
const Features = () => {
  const { t } = useTranslation();

  const sectionRef = useRef(null);
  const imageRefs = useRef([]);
  const textRefs = useRef([]);
  const buttonRef = useRef(null);

  useGSAP(() => {
    imageRefs.current.forEach((img, i) => {
      gsap.from(img, {
        opacity: 0,
        x: i % 2 === 0 ? -100 : 100,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: img,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });
    });

    textRefs.current.forEach((text) => {
      gsap.from(text, {
        opacity: 0,
        y: 30,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: text,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });
    });

    gsap.from(buttonRef.current, {
      opacity: 0,
      y: 40,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: buttonRef.current,
        start: "top 85%",
        toggleActions: "play none none none",
      },
    });
  }, []);

  const sections = [
    {
      key: "HUMAN_HANDOFF",
      image: liveChatImages.liveChat_img_2,
      reverse: false,
    },
    {
      key: "ALL_CHANNELS",
      image: liveChatImages.liveChat_img_3,
      reverse: true,
    },
    {
      key: "AI_HUMAN_EXPERTISE",
      image: liveChatImages.liveChat_img_4,
      reverse: false,
    },
    {
      key: "CUSTOM_FIELDS",
      image: liveChatImages.liveChat_img_5,
      reverse: true,
    },
  ];

  return (
    <Box className="live-chat-features-section" ref={sectionRef}>
      {sections.map((sec, index) => (
        <Box key={sec.key} className="live-chat-features-container">
          <Grid
            container
            spacing={4}
            className="live-chat-features-grid-container"
            direction={sec.reverse ? "row-reverse" : "row"}
          >
            <Grid
              item
              xs={12}
              md={6}
              className="live-chat-features-image-container"
              ref={(el) => (imageRefs.current[index] = el)}
            >
              <img
                className="live-chat-features-image"
                src={sec.image}
                alt={t(`LIVE_CHAT_PAGE.FEATURES.${sec.key}.IMAGE_ALT`)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="live-chat-features-left"
              ref={(el) => (textRefs.current[index] = el)}
            >
              <Typography variant="h3">
                {t(`LIVE_CHAT_PAGE.FEATURES.${sec.key}.TITLE`)}
              </Typography>
              <Typography variant="subtitle1">
                {t(`LIVE_CHAT_PAGE.FEATURES.${sec.key}.DESCRIPTION`)}
              </Typography>
            </Grid>
          </Grid>
          {sec.key === "CUSTOM_FIELDS" && (
            <Stack
              direction="row"
              spacing={2}
              className="live-chat-features-stack-buttons"
              ref={buttonRef}
            >
              <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
                <Button variant="contained" className="live-chat-features-demo-button">
                  {t("COMMON.REQUEST_DEMO_BUTTON")}
                </Button>
              </Link>
              <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                <Button variant="outlined" className="live-chat-features-trial-button">
                  {t("COMMON.START_TRIAL_BUTTON")}
                </Button>
              </Link>
            </Stack>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Features;
