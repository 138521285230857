import React, { useRef } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import {
    SIGN_UP_URL
} from "./../../utils/constants";
// --- CSS ---
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

const SmoothConversations = () => {
    const { t } = useTranslation();
    const sectionRef = useRef(null);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const buttonRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });

        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.3,
            ease: "power2.out"
        })
            .from(subtitleRef.current, {
                opacity: 0,
                y: 20,
                duration: 0.3,
                ease: "power2.out"
            })
            .from(buttonRef.current, {
                opacity: 0,
                y: 40,
                duration: 0.3,
                ease: "power2.out"
            })
    });

    return (
        <Box className="smooth-conversations-container" ref={sectionRef}>
            <Typography variant="h3" ref={titleRef}>
                {t("WORKFLOWS_PAGE.SMOOTH_CONVERSATIONS.TITLE")}
            </Typography>
            <Typography variant="subtitle1" ref={subtitleRef}>
                {t("WORKFLOWS_PAGE.SMOOTH_CONVERSATIONS.DESCRIPTION")}
            </Typography>
            <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                <Button variant="outlined" ref={buttonRef}>
                    {t("WORKFLOWS_PAGE.SMOOTH_CONVERSATIONS.BUTTON")}
                </Button>
            </Link>
        </Box>
    );
};

export default SmoothConversations;