import React, { useRef } from "react";
import { Typography, Button, Box, Stack, Link } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import {
  SIGN_UP_URL,
} from "./../../utils/constants";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const Hero = () => {
  const sectionRef = useRef(null);
  const overlayRef = useRef(null);
  const textRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const buttonRef = useRef(null);
  const noCardRef = useRef(null);
  const { t } = useTranslation();
  const overlayText = t("COPILOT_PAGE.HERO.OVERLAY_TEXT");

  useGSAP(() => {
    const textEl = textRef.current;
    if (!textEl) return;
    textEl.innerHTML = "";
    overlayText.split("").forEach((char) => {
      const span = document.createElement("span");
      span.textContent = char;
      textEl.appendChild(span);
    });
    const entrance = gsap.timeline();
    entrance
      .fromTo(titleRef.current, {
        opacity: 0,
        y: 60,
        scale: 0.95,
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.5,
        ease: "power2.out",
      })
      .fromTo(descRef.current, {
        opacity: 0,
        y: 10,
        scale: 0.95,
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.2,
        ease: "power2.out",
      })
      .fromTo(buttonRef.current, {
        opacity: 0,
        y: 10,
        scale: 0.95,
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.2,
        ease: "power2.out",
      })
      .fromTo(noCardRef.current, {
        opacity: 0,
        y: 10,
        scale: 0.95,
      }, {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.2,
        ease: "power2.out",
      })
    const scrollTl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top",
        end: "+=100%",
        scrub: 1,
        pin: true,
        anticipatePin: 1,
      },
    });
    scrollTl.fromTo(
      overlayRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1 }
    ).fromTo(
      textEl.querySelectorAll("span"),
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.02, duration: 0.1 },
      "-=0.5"
    );
    return () => {
      scrollTl.scrollTrigger?.kill();
      scrollTl.kill();
      entrance.kill();
    };
  }, { dependencies: [overlayText] });


  return (
    <Box ref={sectionRef} className="copilot-hero-section">
      <Box className="copilot-hero-content">
        <Typography variant="h1" ref={titleRef}>
          {t("COPILOT_PAGE.HERO.TITLE")}
        </Typography>
        <Typography variant="h3" ref={descRef}>
          {t("COPILOT_PAGE.HERO.DESCRIPTION")}
        </Typography>
        <Stack direction="column" spacing={2} sx={{ alignItems: "center", width: "100%" }}>
          <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
            <Button variant="contained" ref={buttonRef}>
              {t("COMMON.SIGN_UP_FREE_BUTTON")}
            </Button>
          </Link>
          <Typography variant="subtitle1" ref={noCardRef}>
            {t("COPILOT_PAGE.HERO.NO_CREDIT_CARD")}
          </Typography>
        </Stack>
      </Box>
      <Box ref={overlayRef} className="black-overlay">
        <Typography variant="h2" ref={textRef}></Typography>
      </Box>
    </Box>
  );
}

export default Hero;
