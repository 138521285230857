// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Button, Box, Card, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
// --- CSS ---
import "./index.css";

import {
    SIGN_UP_URL,
} from "./../../utils/constants";

// --- Image Utilities ---
import { tryForFreeImages } from "../../utils/imageUtils";

gsap.registerPlugin(ScrollTrigger);

// --- LevelUp Component ---
const LevelUp = ({ title, subtitle, button, isLiveChatPage = false, isWorkflowsPage = false }) => {
    const sectionRef = useRef(null);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const buttonRef = useRef(null);
    const imageRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });
    
        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.3,
            ease: "power2.out"
        })
        .from(subtitleRef.current, {
            opacity: 0,
            y: 20,
            duration: 0.3,
            ease: "power2.out"
        })
        .from(buttonRef.current, {
            opacity: 0,
            y: 40,
            duration: 0.3,
            ease: "power2.out"
        })
        .fromTo(imageRef.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.3,
            ease: "power2.out"
        });
    });
    

    return (
        <Box
            className={`level-up-section ${isLiveChatPage ? "live-chat-background" : isWorkflowsPage ? "workflows-background" : ""
                }`}
                ref={sectionRef}
        >
            <Card className="level-up-card">
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} position="relative">
                    <Box
                        className="level-up-card-left-content"
                        flex={{ xs: '1 1 100%', md: '1 1 60%' }}
                    >
                        <Typography variant="h3" ref={titleRef}>{title}</Typography>
                        <Typography variant="subtitle1" ref={subtitleRef}>{subtitle}</Typography>
                        <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                            <Button className="free-button" target="_blank" ref={buttonRef}>
                                {button}
                            </Button>
                        </Link>
                    </Box>
                    <Box
                        className="level-up-card-right-content"
                        flex={{ xs: '1 1 100%', md: '1 1 40%' }}
                    >
                        <img src={tryForFreeImages.Logomark} alt="Logomark" ref={imageRef}/>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default LevelUp;
