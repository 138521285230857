import React, { useRef } from "react";
import { Typography, Button, Stack, Box } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const Hero = () => {
  const sectionRef = useRef(null);
  const overlayRef = useRef(null);
  const textRef = useRef(null);
  const { t } = useTranslation();

  const overlayText = t("AGENTS_PAGE.HERO_SECTION.OVERLAY_TEXT");

  useGSAP(
    () => {
      const textEl = textRef.current;
      if (!textEl) return;

      // Clear and populate with spans
      textEl.innerHTML = "";
      overlayText.split("").forEach((char) => {
        const span = document.createElement("span");
        span.textContent = char;
        textEl.appendChild(span);
      });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top top",
          end: "+=100%",
          scrub: 1,
          pin: true,
          anticipatePin: 1,
          markers: false,
        },
      });

      tl.fromTo(
        overlayRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1 }
      ).fromTo(
        textEl.querySelectorAll("span"),
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, stagger: 0.02, duration: 0.1 },
        "-=0.5"
      );

      return () => {
        tl.scrollTrigger?.kill();
        tl.kill();
      };
    },
    { scope: sectionRef, dependencies: [overlayText] }
  );

  return (
    <Box ref={sectionRef} className="agents-hero-section">
      <Box className="agents-hero-content">
        <Typography variant="h1">
          {t("AGENTS_PAGE.HERO_SECTION.TITLE")}
        </Typography>
        <Typography variant="h3">
          {t("AGENTS_PAGE.HERO_SECTION.DESCRIPTION")}
        </Typography>
        <Stack direction="row" spacing={2} className="agents-stack-buttons">
          <Button
            className="conversational-button"
            onClick={() =>
              document.getElementById("conversational-ai")?.scrollIntoView({ behavior: "smooth" })
            }
          >
            {t("COMMON.CONVERSATIONAL_BUTTON")}
          </Button>
          <Button
            className="vertical-button"
            onClick={() =>
              document.getElementById("vertical-ai")?.scrollIntoView({ behavior: "smooth" })
            }
          >
            {t("COMMON.VERTICAL_BUTTON")}
          </Button>
        </Stack>
      </Box>
      <Box ref={overlayRef} className="black-overlay">
        <Typography variant="h2" ref={textRef}></Typography>
      </Box>
    </Box>
  );
};

export default Hero;
