// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Box, Grid, Button, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

// --- Image Utilities ---
import { workflowsImages } from "../../utils/imageUtils";
import {
  SIGN_UP_URL,
  GET_DEMO_URL,
} from "./../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

// --- Features Component ---
const Features = () => {
  const { t } = useTranslation();

  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const imageRefs = useRef([]);
  const textRefs = useRef([]);
  const buttonRef = useRef(null);

  useGSAP(() => {
    gsap.from(titleRef.current, {
      opacity: 0,
      y: 30,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 85%",
        toggleActions: "play none none none",
      },
    });
    gsap.from(subtitleRef.current, {
      opacity: 0,
      y: 20,
      duration: 1,
      delay: 0.1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: subtitleRef.current,
        start: "top 85%",
        toggleActions: "play none none none",
      },
    });
    imageRefs.current.forEach((img, i) => {
      gsap.from(img, {
        opacity: 0,
        x: i % 2 === 0 ? 100 : -100,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: img,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });
    });
    textRefs.current.forEach((text) => {
      gsap.from(text, {
        opacity: 0,
        y: 30,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: text,
          start: "top 80%",
          toggleActions: "play none none none",
        },
      });
    });
    gsap.from(buttonRef.current, {
      opacity: 0,
      y: 40,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: buttonRef.current,
        start: "top 85%",
        toggleActions: "play none none none",
      },
    });
  });

  const features = [
    {
      key: "READY_TEMPLATES",
      image: workflowsImages.workflows_img_2,
      reverse: false,
    },
    {
      key: "ESCALATION",
      image: workflowsImages.workflows_img_3,
      reverse: true,
    },
    {
      key: "VISUAL_BUILDER",
      image: workflowsImages.workflows_img_4,
      reverse: false,
    },
  ];

  return (
    <Box className="workflows-features-section" ref={sectionRef}>
      <Typography
        variant="h3"
        className="workflows-features-section-title"
        ref={titleRef}
      >
        {t("WORKFLOWS_PAGE.FEATURES_SECTION.TITLE")}
      </Typography>
      <Typography
        variant="subtitle1"
        className="workflows-features-section-subtitle"
        ref={subtitleRef}
      >
        {t("WORKFLOWS_PAGE.FEATURES_SECTION.SUBTITLE")}
      </Typography>

      {features.map((feature, index) => (
        <Box className="workflows-features-container" key={feature.key}>
          <Grid
            container
            spacing={4}
            className="workflows-features-grid-container"
            direction={feature.reverse ? "row-reverse" : "row"}
          >
            <Grid
              item
              xs={12}
              md={6}
              className="workflows-features-left"
              ref={(el) => (textRefs.current[index] = el)}
            >
              <Typography variant="h3">
                {t(`WORKFLOWS_PAGE.FEATURES_SECTION.${feature.key}.TITLE`)}
              </Typography>
              <Typography variant="subtitle1">
                {t(`WORKFLOWS_PAGE.FEATURES_SECTION.${feature.key}.DESCRIPTION`)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="workflows-features-image-container"
              ref={(el) => (imageRefs.current[index] = el)}
            >
              <img
                className="workflows-features-image"
                src={feature.image}
                alt={t(`WORKFLOWS_PAGE.FEATURES_SECTION.${feature.key}.IMAGE_ALT`)}
              />
            </Grid>
          </Grid>
        </Box>
      ))}

      <Stack
        direction="row"
        spacing={2}
        className="workflows-features-section-stack-buttons"
        ref={buttonRef}
      >
        <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
          <Button variant="contained" className="workflows-demo-button">
            {t("COMMON.REQUEST_DEMO_BUTTON")}
          </Button>
        </Link>
        <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
          <Button variant="outlined" className="workflows-trial-button">
            {t("COMMON.START_TRIAL_BUTTON")}
          </Button>
        </Link>
      </Stack>
    </Box>
  );
};

export default Features;
