import React, { useEffect, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { businessImages } from "../../utils/imageUtils";

gsap.registerPlugin(ScrollTrigger);

const BusinessOutcomes = () => {
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);
    const titleRef = useRef(null);
    const { t } = useTranslation();

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });
        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.3,
            ease: "power2.out"
        })
            .from(cardsRef.current, {
                opacity: 0,
                y: 30,
                duration: 0.5,
                stagger: 0.2,
                ease: "power1.out",
            });
    });

    return (
        <Box ref={sectionRef}>
            <Box className="business-metrics">
                <Typography variant="h3" ref={titleRef}>
                    {t("HOME_PAGE.BUSINESS_OUTCOMES.TITLE")}
                </Typography>
                <Grid container spacing={4} className="metrics-grid">
                    {[
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.LOWER_COSTS.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.LOWER_COSTS.LABEL") },
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.AI_RESOLVED.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.AI_RESOLVED.LABEL") },
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_SATISFACTION.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_SATISFACTION.LABEL") },
                        { value: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_CONVERSIONS.VALUE"), label: t("HOME_PAGE.BUSINESS_OUTCOMES.METRICS.HIGHER_CONVERSIONS.LABEL"), },
                    ].map((item, index) => (
                        <>
                            <Grid key={index} item xs={12} sm={6} md={3} className="metric-item" ref={(el) => (cardsRef.current[index] = el)}>
                                <Typography variant="h2" className="metric-value">
                                    {item.value}
                                </Typography>
                                <Typography variant="subtitle1" className="metric-label">
                                    {item.label}
                                </Typography>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Box>
            {/* <Box className="partners-section">
                <Grid container spacing={4} justifyContent="center">
                    {[
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.BACKED_BY.LABEL"), image: businessImages.startup_wise_guys, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.BACKED_BY.ALT") },
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_NVIDIA.LABEL"), image: businessImages.nvidia, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_NVIDIA.ALT") },
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_MICROSOFT.LABEL"), image: businessImages.microsoft, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.SUPPORTED_BY_MICROSOFT.ALT") },
                        { label: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.PRODUCT_OF_THE_DAY.LABEL"), image: businessImages.product_hunt, alt: t("HOME_PAGE.BUSINESS_OUTCOMES.PARTNERS.PRODUCT_OF_THE_DAY.ALT") },
                    ].map((partner, index) => (
                        <Grid key={index} item xs={12} sm={6} md={3} className="partner-item">
                            <Typography variant="body2" className="partner-label">
                                {partner.label}
                            </Typography>
                            <img src={partner.image} alt={partner.alt} className="partner-logo" />
                        </Grid>
                    ))}
                </Grid>
            </Box> */}
        </Box>
    );
};

export default BusinessOutcomes;
