import React, { useRef } from "react";
import { useMemo } from "react";
import { Box, Grid, Typography, Card, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const WorkflowTimeline = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const timelineRef = useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 50%",
        toggleActions: "play none none none"
      },
    });
    tl.from(titleRef.current, {
      opacity: 0,
      y: 30,
      duration: 0.3,
      ease: "power2.out"
    })
      .from(subtitleRef.current, {
        opacity: 0,
        y: 20,
        duration: 0.3,
        ease: "power2.out"
      })
      .from(timelineRef.current, {
        opacity: 0,
        duration: 1.0,
        ease: "power2.out"
      })
  });

  const workflowSteps = useMemo(() => [
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MESSAGES.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MESSAGES.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.BUTTONS.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.BUTTONS.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TAGS.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TAGS.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TICKET.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.TICKET.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.DATA.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.DATA.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MEDIA.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.MEDIA.DESCRIPTION")
    },
    {
      title: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.AI.TITLE"),
      description: t("WORKFLOWS_PAGE.TIMELINE_SECTION.STEPS.AI.DESCRIPTION")
    }
  ], [t]);

  return (
    <Box className="workflow-container" ref={sectionRef}>
      <Typography variant="h3" className="workflow-title" ref={titleRef}>
        {t("WORKFLOWS_PAGE.TIMELINE_SECTION.TITLE")}
      </Typography>
      <Typography variant="subtitle1" className="workflow-subtitle" ref={subtitleRef}>
        {t("WORKFLOWS_PAGE.TIMELINE_SECTION.SUBTITLE")}
      </Typography>
      {isMobile ? (
        <>
          {workflowSteps.map((step, index) => (
            <Card className="card-container" ref={timelineRef}>
              <Typography variant="h5">{step.title}</Typography>
              <Typography variant="body1">{step.description}</Typography>
            </Card>
          ))}
        </>
      ) : (
        <Grid container spacing={4} className="timeline-grid" ref={timelineRef}>
          <Box className="timeline-line" />
          {workflowSteps.map((step, index) => (
            <Grid container item key={index} className="timeline-step">
              {index % 2 === 0 ? (
                <>
                  <Grid item xs={5} className="left-align">
                    <Card className="card-container">
                      <Typography variant="h5">{step.title}</Typography>
                      <Typography variant="body1">{step.description}</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={2} className="timeline-dot-container">
                    <Box className="timeline-dot">
                      <Box className="timeline-inner-dot" />
                    </Box>
                  </Grid>
                  <Grid item xs={5} />
                </>
              ) : (
                <>
                  <Grid item xs={5} />
                  <Grid item xs={2} className="timeline-dot-container">
                    <Box className="timeline-dot">
                      <Box className="timeline-inner-dot" />
                    </Box>
                  </Grid>
                  <Grid item xs={5} className="right-align">
                    <Card className="card-container">
                      <Typography variant="h5">{step.title}</Typography>
                      <Typography variant="body1">{step.description}</Typography>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default WorkflowTimeline;