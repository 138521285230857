// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Box, Grid, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BsBoxArrowInRight, BsGraphUpArrow } from "react-icons/bs";
import { PiCurrencyCircleDollarLight } from "react-icons/pi";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

// --- Steps Component ---
const Steps = () => {
    const { t } = useTranslation();
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);
    const titleRef = useRef(null);

    const features = [
        {
            key: "JOIN",
            icon: <BsBoxArrowInRight className="steps-icon" />,
            bgColor: "rgba(64, 117, 221, 1)"
        },
        {
            key: "PROMOTE",
            icon: <BsGraphUpArrow className="steps-icon" />,
            bgColor: "rgba(148, 87, 255, 1)"
        },
        {
            key: "GET_PAID",
            icon: <PiCurrencyCircleDollarLight className="steps-icon" />,
            bgColor: "rgba(129, 201, 109, 1)"
        }
    ];

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });
        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.3,
            ease: "power2.out"
        })
            .from(cardsRef.current, {
                opacity: 0,
                y: 30,
                duration: 0.5,
                stagger: 0.2,
                ease: "power1.out",
            });
    });

    return (
        <Box className="steps-container" ref={sectionRef}>
            <Typography variant="h3" ref={titleRef}>
                {t("PARTNER_PAGE.STEPS.TITLE")}
            </Typography>
            <Grid container spacing={4}>
                {features.map((feature, index) => (
                    <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="steps-card"
                        ref={(el) => (cardsRef.current[index] = el)}
                    >
                        <motion.div whileHover={{ scale: 1.05 }} className="steps-card-content">
                            <Box
                                className="steps-circle"
                                style={{ background: feature.bgColor }}
                            >
                                {feature.icon}
                            </Box>
                            <Typography variant="h5" className="steps-title">
                                {t(`PARTNER_PAGE.STEPS.${feature.key}.TITLE`)}
                            </Typography>
                            <Typography variant="body1" className="steps-description">
                                {feature.key === "JOIN" ? (
                                    <>
                                        <Link to="https://intelswift.firstpromoter.com" target="_blank" className="steps-signup-link">
                                            {t("PARTNER_PAGE.STEPS.JOIN.SIGN_UP")}
                                        </Link>
                                        {" "}{t("PARTNER_PAGE.STEPS.JOIN.DESCRIPTION")}
                                    </>
                                ) : (
                                    t(`PARTNER_PAGE.STEPS.${feature.key}.DESCRIPTION`)
                                )}
                            </Typography>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Steps;
