import React, { useRef } from "react";
import { Typography, Box, Grid, Button, Card, CardContent } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import { integrationsImages } from "../../utils/imageUtils";
import "./index.css";

gsap.registerPlugin(ScrollTrigger);

// --- Integration Data ---
const Integrations = () => {
    const sectionRef = useRef(null);
        const titleRef = useRef(null);
    const { t } = useTranslation();

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });
        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.3,
            ease: "power2.out"
        })
    });

    const integrations = [
        { key: "CALENDLY", logo: integrationsImages.calendly_logo },
        { key: "STRIPE", logo: integrationsImages.stripe_logo },
        { key: "ZENDESK", logo: integrationsImages.zendesk_logo },
        { key: "MAILCHIMP", logo: integrationsImages.mailchimp_logo },
        { key: "JIRA", logo: integrationsImages.jira_logo },
        { key: "SALESFORCE", logo: integrationsImages.salesforce_logo, faded: true },
        { key: "ZOHO_CRM", logo: integrationsImages.zoho_logo, faded: true },
        { key: "PIPEDRIVE_CRM", logo: integrationsImages.piperdrive_logo, faded: true },
        { key: "HUBSPOT_CRM", logo: integrationsImages.hubspot_logo, faded: true }
    ];

    return (
        <Box className="integrations-section" ref={sectionRef}>
            <Typography variant="h3" ref={titleRef}>
                {t("INTEGRATIONS_COMPONENT.TITLE")}
            </Typography>
            <Grid container spacing={3} className="integrations-grid">
                {integrations.map((integration, index) => (
                    <Grid
                        item xs={6} sm={4} md={3} lg={2.4}
                        key={index}
                    >
                        <Card className="integration-card">
                            <CardContent className="integration-content">
                                <img src={integration.logo} alt={t(`INTEGRATIONS_COMPONENT.TOOLS.${integration.key}`)} className="integration-logo" />
                                <Typography>{t(`INTEGRATIONS_COMPONENT.TOOLS.${integration.key}`)}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {/* <Button>
                {t("INTEGRATIONS_COMPONENT.BUTTON")}
            </Button> */}
        </Box>
    );
};

export default Integrations;
