// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BsGraphUpArrow } from "react-icons/bs";
import { LuTrendingDown } from "react-icons/lu";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

// --- Advantages Component ---
const Advantages = () => {
    const { t } = useTranslation();
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);

    const advantages = [
        {
            key: "BOOST_EFFICIENCY",
            icon: <BsGraphUpArrow className="advantages-icon" />,
            bgColor: "rgba(64, 117, 221, 1)"
        },
        {
            key: "CUSTOMER_EXPERIENCE",
            icon: <HiOutlineFaceSmile className="advantages-icon" />,
            bgColor: "rgba(148, 87, 255, 1)"
        },
        {
            key: "LESS_BURNOUT",
            icon: <LuTrendingDown className="advantages-icon" />,
            bgColor: "rgba(87, 179, 255, 1)"
        },
    ];

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });
        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.3,
            ease: "power2.out"
        })
            .from(subtitleRef.current, {
                opacity: 0,
                y: 20,
                duration: 0.3,
                ease: "power2.out"
            })
            .from(cardsRef.current, {
                opacity: 0,
                y: 30,
                duration: 0.5,
                stagger: 0.2,
                ease: "power1.out",
            });
    });

    return (
        <Box className="advantages-container" ref={sectionRef}>
            <Typography variant="h3" ref={titleRef}>
                {t("COPILOT_PAGE.ADVANTAGES.TITLE")}
            </Typography>
            <Typography variant="subtitle1" ref={subtitleRef}>
                {t("COPILOT_PAGE.ADVANTAGES.SUBTITLE")}
            </Typography>
            <Grid container spacing={4}>
                {advantages.map((advantage, index) => (
                    <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className="advantages-card"
                        ref={(el) => (cardsRef.current[index] = el)}
                    >
                        <motion.div whileHover={{ scale: 1.05 }} className="advantages-card-content">
                            <Box
                                className="advantages-circle"
                                style={{ background: advantage.bgColor }}
                            >
                                {advantage.icon}
                            </Box>
                            <Typography variant="h5" className="advantages-title">
                                {t(`COPILOT_PAGE.ADVANTAGES.${advantage.key}.TITLE`)}
                            </Typography>
                            <Typography variant="body1" className="advantages-description">
                                {t(`COPILOT_PAGE.ADVANTAGES.${advantage.key}.DESCRIPTION`)}
                            </Typography>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Advantages;