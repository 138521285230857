// --- Third Party Libraries ---
import React, { useRef } from "react";
import { Typography, Box, Grid, Button, Stack, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

// --- Image Utilities ---
import { copilotImages } from "../../utils/imageUtils";
import {
    SIGN_UP_URL,
    GET_DEMO_URL,
} from "./../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

// --- CopilotFeatures Component ---
const CopilotFeatures = () => {
    const { t } = useTranslation();
    const sectionRef = useRef(null);
    const titleRef = useRef(null);
    const imageRefs = useRef([]);
    const textRefs = useRef([]);
    const buttonRef = useRef(null); 

    const features = [
        {
          titleKey: "GENERATE_PROMPTS",
          image: copilotImages.copilot_img_1,
          reverse: true,
        },
        {
          titleKey: "DATA_VISUALIZATION",
          image: copilotImages.copilot_img_2,
          reverse: true,
        },
        {
          titleKey: "AI_SUMMARY",
          image: copilotImages.copilot_img_3,
          reverse: true,
        },
        {
          titleKey: "AI_ONBOARDING",
          image: copilotImages.copilot_img_4,
          reverse: true,
        },
      ];

    useGSAP(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "top 80%",
                toggleActions: "play none none none"
            },
        });
        tl.from(titleRef.current, {
            opacity: 0,
            y: 30,
            duration: 1,
            ease: "power2.out"
        });
        imageRefs.current.forEach((img, i) => {
            gsap.from(img, {
              opacity: 0,
              x: i % 2 === 0 ? 100 : -100,
              duration: 1,
              ease: "power2.out",
              scrollTrigger: {
                trigger: img,
                start: "top 80%",
                toggleActions: "play none none none",
              },
            });
          });
          textRefs.current.forEach((text) => {
            gsap.from(text, {
              opacity: 0,
              y: 30,
              duration: 1,
              ease: "power2.out",
              scrollTrigger: {
                trigger: text,
                start: "top 80%",
                toggleActions: "play none none none",
              },
            });
          });
          gsap.from(buttonRef.current, {
            opacity: 0,
            y: 40,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: buttonRef.current,
              start: "top 80%",
              toggleActions: "play none none none",
            },
          });
    });

    return (
        <Box className="copilot-features-container" ref={sectionRef}>
          <Typography variant="h4" ref={titleRef}>
            {t("COPILOT_PAGE.FEATURES.TITLE")}
          </Typography>
            {features.map((feature, index) => (
              <Grid container spacing={4} key={feature.titleKey} className="copilot-grid-container" direction={feature.reverse ? "row-reverse" : "row"}>
                {feature.reverse ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="copilot-features-image-container"
                      ref={(el) => (imageRefs.current[index] = el)}
                    >
                      <img
                        className="copilot-features-image"
                        src={feature.image}
                        alt={t(`COPILOT_PAGE.FEATURES.FEATURES_LIST.${feature.titleKey}.IMAGE_ALT`)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="copilot-features-left"
                      ref={(el) => (textRefs.current[index] = el)}
                    >
                      <Typography variant="h3">
                        {t(`COPILOT_PAGE.FEATURES.FEATURES_LIST.${feature.titleKey}.TITLE`)}
                      </Typography>
                      <Typography variant="subtitle1">
                        {t(`COPILOT_PAGE.FEATURES.FEATURES_LIST.${feature.titleKey}.DESCRIPTION`)}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="copilot-features-left"
                      ref={(el) => (textRefs.current[index] = el)}
                    >
                      <Typography variant="h3">
                        {t(`COPILOT_PAGE.FEATURES.FEATURES_LIST.${feature.titleKey}.TITLE`)}
                      </Typography>
                      <Typography variant="subtitle1">
                        {t(`COPILOT_PAGE.FEATURES.FEATURES_LIST.${feature.titleKey}.DESCRIPTION`)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="copilot-features-image-container"
                      ref={(el) => (imageRefs.current[index] = el)}
                    >
                      <img
                        className="copilot-features-image"
                        src={feature.image}
                        alt={t(`COPILOT_PAGE.FEATURES.FEATURES_LIST.${feature.titleKey}.IMAGE_ALT`)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
            <Stack direction="row" spacing={2} className="stack-buttons" ref={buttonRef}>
              <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
                <Button variant="contained">{t("COMMON.REQUEST_DEMO_BUTTON")}</Button>
              </Link>
              <Link component={RouterLink} to={SIGN_UP_URL} target="_blank" underline="none">
                <Button variant="outlined" className="copilot-features-trial-button">
                  {t("COMMON.START_TRIAL_BUTTON")}
                </Button>
              </Link>
            </Stack>
        </Box>
      );
    };
    
    export default CopilotFeatures;
