import React, { useRef } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { GET_DEMO_URL } from "./../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

const Partnerships = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const buttonRef = useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%",
        toggleActions: "play none none none"
      },
    });
    tl.from(titleRef.current, {
      opacity: 0,
      y: 30,
      duration: 0.3,
      ease: "power2.out"
    })
    .from(subtitleRef.current, {
      opacity: 0,
      y: 20,
      duration: 0.3,
      ease: "power2.out"
  })
      .from(buttonRef.current, {
        opacity: 0,
        y: 40,
        duration: 0.3,
        ease: "power2.out"
      })
  });

  return (
    <Box className="partnerships-section" ref={sectionRef}>
      <Typography variant="h3" ref={titleRef}>
        {t("PARTNERSHIP_PAGE.PARTNERSHIPS_SECTION.TITLE")}
      </Typography>
      <Typography variant="subtitle1" ref={subtitleRef}>
        {t("PARTNERSHIP_PAGE.PARTNERSHIPS_SECTION.SUBTITLE")}
      </Typography>
      <Link component={RouterLink} to={GET_DEMO_URL} target="_blank" underline="none">
        <Button variant="contained" className="live-chat-features-demo-button" ref={buttonRef}>
          {t("PARTNERSHIP_PAGE.PARTNERSHIPS_SECTION.BUTTON")}
        </Button>
      </Link>
    </Box>
  );
};

export default Partnerships;
