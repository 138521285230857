import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box, Stack } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { solutionsImages } from "../../utils/imageUtils";
import useMediaQuery from '@mui/material/useMediaQuery';

// --- Constants ---
import {
  SIGN_UP_URL
} from "../../utils/constants";

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const blurContainerRef = useRef(null);
  const mainSectionRef = useRef(null);
  const mainContentRef = useRef(null);
  const chatOverlayRef = useRef(null);
  const imageRef = useRef(null);
  const heroImageRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:768px)');

  useGSAP(() => {
    const mm = gsap.matchMedia();
    gsap.fromTo(
      blurContainerRef.current,
      { opacity: 0, scale: 0.95 },
      { opacity: 1, scale: 1, duration: 1.2, ease: "power2.out" }
    );
    gsap.fromTo(
      heroImageRef.current,
      { opacity: 0, scale: 0.95 },
      { opacity: 1, scale: 1, duration: 1.2, ease: "power2.out", delay: 0.2 }
    );
    mm.add(
      {
        isMobile: "(max-width: 768px)",
        isTablet: "(min-width: 769px) and (max-width: 1024px)",
        isSmallDesktop: "(min-width: 1025px) and (max-width: 1440px)",
        isLargeDesktop: "(min-width: 1441px)",
      },
      (context) => {
        const { isMobile, isTablet, isSmallDesktop } = context.conditions;

        if (isMobile) {
          [blurContainerRef, imageRef, mainContentRef].forEach(ref => {
            if (ref.current) {
              gsap.set(ref.current, { clearProps: "all" });
            }
          });
          return;
        }
        const initialSize = isTablet
          ? { width: "80vw", height: "30vh" }
          : isSmallDesktop
            ? { width: "65vw", height: "25vh" }
            : { width: "60vw", height: "35vh" };

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: mainSectionRef.current,
            start: "top top",
            pin: true,
            scrub: 1,
          },
        });
        tl.fromTo(
          blurContainerRef.current,
          {
            width: initialSize.width,
            height: initialSize.height,
            opacity: 2.0,
            borderWidth: "1px",
            borderColor: "rgba(255,255,255,0.4)",
          },
          {
            opacity: 1,
            width: "100%",
            height: "100vh",
            borderColor: "rgba(255,255,255,0)",
            duration: 1.2,
            ease: "power2.out",
          }
        );
        // Step 1: Expand blur container
        tl.to(
          mainContentRef.current,
          {
            y: "0",
            opacity: 0,
            pointerEvents: "none",
            duration: 0.6,
            ease: "power2.out",
          },
          "<" // start at same time as blur container animation
        );

        // Step 2: Wait for main content to hide before image fades in
        tl.fromTo(
          imageRef.current,
          {
            scale: 0.2,
            opacity: 0,
            filter: "blur(20px)",
          },
          {
            scale: 1,
            opacity: 1,
            filter: "blur(0px)",
            duration: 1.2,
            ease: "power2.out",
          },
          "+=0.4" // slight delay after main content is hidden
        );

        const chatRows = gsap.utils.toArray(chatOverlayRef.current.querySelectorAll(".chat-row"));
        gsap.from(chatRows, {
          opacity: 0,
          y: 20,
          duration: 0.9,
          stagger: 0.4,
          ease: "power2.out",
          delay: 0.5,
        });
        return () => {
          tl.kill();
          [blurContainerRef, mainContentRef, imageRef].forEach(ref => {
            if (ref.current) {
              gsap.set(ref.current, { clearProps: "all" });
            }
          });
        };
      }
    );
    return () => mm.revert();
  }, { scope: containerRef });

  return (
    <Box className="main-section" ref={mainSectionRef}>
      {!isMobile ? (
        <>
          <Box className="middle-image-container">
            <img
              ref={heroImageRef}
              className="middle-image hero-img"
              src={solutionsImages.hero_img}
              alt={t("HOME_PAGE.HERO_SECTION.HERO_IMAGE_ALT")}
            />
            <Box className="chat-overlay" ref={chatOverlayRef}>
              <Box className="chat-row right">
                <Box className="user-initial">{t("HOME_PAGE.HERO_SECTION.CHAT.USER_NAME_FIRST_LETTER")}</Box>
                <Box className="message-column right">
                  <Typography variant="body2" className="user-name">{t("HOME_PAGE.HERO_SECTION.CHAT.USER_NAME")}</Typography>
                  <Box className="chat-bubble user">
                    <Typography variant="body1">
                      {t("HOME_PAGE.HERO_SECTION.CHAT.MESSAGE_1")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="chat-row left">
                <Box className="chat-avatar">
                  <img src={solutionsImages.hero_ai_avatar} alt={t("HOME_PAGE.HERO_SECTION.CHAT.AI_AVATAR_ALT")} />
                </Box>
                <Box className="message-column left">
                  <Typography variant="body2" className="user-name">{t("HOME_PAGE.HERO_SECTION.CHAT.AI_NAME")}</Typography>
                  <Box className="chat-bubble ai">
                    <Typography variant="body1">
                      {t("HOME_PAGE.HERO_SECTION.CHAT.MESSAGE_2")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="chat-row right">
                <Box className="user-initial">{t("HOME_PAGE.HERO_SECTION.CHAT.USER_NAME_FIRST_LETTER")}</Box>
                <Box className="message-column right">
                  <Typography variant="body2" className="user-name">{t("HOME_PAGE.HERO_SECTION.CHAT.USER_NAME")}</Typography>
                  <Box className="chat-bubble user">
                    <Typography variant="body1">
                      {t("HOME_PAGE.HERO_SECTION.CHAT.MESSAGE_3")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="chat-row left">
                <Box className="chat-avatar">
                  <img src={solutionsImages.hero_ai_avatar} alt={t("HOME_PAGE.HERO_SECTION.CHAT.AI_AVATAR_ALT")} />
                </Box>
                <Box className="message-column left">
                  <Typography variant="body2" className="user-name">{t("HOME_PAGE.HERO_SECTION.CHAT.AI_NAME")}</Typography>
                  <Box className="chat-bubble ai">
                    <Typography variant="body1">
                      {t("HOME_PAGE.HERO_SECTION.CHAT.MESSAGE_4")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="chat-row right">
                <Box className="user-initial">{t("HOME_PAGE.HERO_SECTION.CHAT.USER_NAME_FIRST_LETTER")}</Box>
                <Box className="message-column right">
                  <Typography variant="body2" className="user-name">{t("HOME_PAGE.HERO_SECTION.CHAT.USER_NAME")}</Typography>
                  <Box className="chat-bubble user">
                    <Typography variant="body1">
                      {t("HOME_PAGE.HERO_SECTION.CHAT.MESSAGE_5")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box ref={blurContainerRef} className="blur-container">
            <Box className="main-content">
              <Typography variant="h1">{t("HOME_PAGE.HERO_SECTION.TITLE")}</Typography>
              <Typography variant="subtitle1">
                {t("HOME_PAGE.HERO_SECTION.SUBTITLE")}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button className="free-button" href={SIGN_UP_URL} target="_blank">
                  {t("COMMON.TRY_FREE_BUTTON")}
                </Button>
                <Button className="main-marketplace-button" onClick={() => { navigate('/ai-agents') }}>
                  {t("HOME_PAGE.HERO_SECTION.AI_MARKETPLACE")}
                </Button>
              </Stack>
            </Box>
            <Box className="image-container">
              <img
                ref={imageRef}
                className="main-image"
                src={solutionsImages.solutions_img}
                alt={t("HOME_PAGE.HERO_SECTION.SOLUTIONS_IMAGE_ALT")}
              />
            </Box>
          </Box>
        </>
      ) : (
        // MOBILE VERSION WITHOUT BLUR OR IMAGE
        <Box className="mobile-main-content">
          <img
            ref={heroImageRef}
            className="hero-img"
            src={solutionsImages.hero_img}
            alt={t("HOME_PAGE.HERO_SECTION.HERO_IMAGE_ALT")}
          />
          <Box className="mobile-blur">
            <Typography variant="h1">{t("HOME_PAGE.HERO_SECTION.TITLE")}</Typography>
            <Typography variant="subtitle1">{t("HOME_PAGE.HERO_SECTION.SUBTITLE")}</Typography>
            <Stack spacing={2} className="stack-buttons">
              <Button className="free-button" onClick={() => { navigate(SIGN_UP_URL) }}>
                {t("COMMON.TRY_FREE_BUTTON")}
              </Button>
              <Button className="main-marketplace-button" onClick={() => { navigate('/ai-agents') }}>
                {t("HOME_PAGE.HERO_SECTION.AI_MARKETPLACE")}
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default HeroSection;
