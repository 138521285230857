import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"; 

import enTranslation from "./locales/en.json";
import ukTranslation from "./locales/uk.json";

i18n
  .use(LanguageDetector) 
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      uk: { translation: ukTranslation },
    },
    fallbackLng: "en", 
    supportedLngs: ["en", "en"], 
    debug: true, 
    detection: {
      order: ["querystring", "localStorage", "sessionStorage", "navigator", "htmlTag"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      caches: ["localStorage"], 
    },
    interpolation: { escapeValue: false }, 
  });

export default i18n;
