import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import i18n from "./../i18n";
import { useLocation } from 'react-router-dom';

// --- Internal Components ---
import ScrollTriggerFixer from "../utils/scrollTriggerFixer.jsx";
import Home from "../pages/HomePage/index.jsx";
import Copilot from "../pages/Copilot/index.jsx";
import Agents from "../pages/Agents/index.jsx";
import LiveChat from "../pages/LiveChat/index.jsx"
import Partnership from "../pages/Partnership/index.jsx";
import AdvancedAnalytics from "../pages/AdvancedAnalytics/index.jsx";
import Workflows from "../pages/Workflows/index.jsx";
import KnowledgeBase from "../pages/KnowledgeBase/index.jsx"
import Privacy from "../pages/HomePage/Privacy.jsx";
import Conditions from "../pages/Conditions/index.jsx";
import Pricing from "../pages/Pricing/index.jsx";
import Blog from "../pages/Blog/index.jsx";
import About from "../pages/About/index.jsx";
import BlogOne from "../components/Blog/BlogOne.jsx";
import BlogTwo from "../components/Blog/BlogTwo.jsx";
import BlogThree from "../components/Blog/BlogThree.jsx";
import BlogFour from "../components/Blog/BlogFour.jsx";
import BlogFive from "../components/Blog/BlogFive.jsx";
import BlogSix from "../components/Blog/BlogSix.jsx";
import BlogSeven from "../components/Blog/BlogSeven.jsx";
import BlogEighth from "../components/Blog/BlogEight.jsx";
import BlogNine from "../components/Blog/BlogNine.jsx";
import BlogTen from "../components/Blog/BlogTen.jsx";
import BlogEleven from "../components/Blog/BlogEleven.jsx";

// --- Slug Mapping ---
const blogSlugs = {
  1: "ai-sales",
  2: "ai-customer-service-2025",
  3: "chatgpt-vs-gemini",
  4: "intercom-alternatives",
  5: "best-customer-service-automation-software",
  6: "freshworks-alternative",
  7: "zendesk-alternative",
  8: "tidio-alternative",
  9: "helpcrunch-alternatives",
  10: "liveperson-competitors",
  11: "chatbot-alternatives"
};

// --- MainRoutes Component ---
const MainRoutes = () => {
  const { pathname } = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const browserLanguages = navigator.languages.map(lang => lang.split("-")[0]);
    const supportedLangs = ["en", "uk"];
    const selectedLang = browserLanguages.find(lang => supportedLangs.includes(lang)) || "en";
    if (i18n.language !== selectedLang) {
        i18n.changeLanguage(selectedLang);
    }
}, []);

  return (
    <>
          <ScrollTriggerFixer/>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/ai-copilot" element={<Copilot />} />
      <Route exact path="/ai-agents" element={<Agents />} />
      <Route exact path="/ai-helpdesk" element={<LiveChat />} />
      <Route exact path="/partnership" element={<Partnership />} />
      <Route exact path="/ai-analytics" element={<AdvancedAnalytics />} />
      <Route exact path="workflows" element={<Workflows />} />
      <Route exact path="ai-knowledge-base" element={<KnowledgeBase />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/pricing" element={<Pricing />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/privacy-policy" element={<Privacy />} />
      <Route exact path="/terms-and-conditions" element={<Conditions />} />
      <Route exact path={`/blog/${blogSlugs[1]}`} element={<BlogOne />} />
      <Route exact path={`/blog/${blogSlugs[2]}`} element={<BlogTwo />} />
      <Route exact path={`/blog/${blogSlugs[3]}`} element={<BlogThree />} />
      <Route exact path={`/blog/${blogSlugs[4]}`} element={<BlogFour />} />
      <Route exact path={`/blog/${blogSlugs[5]}`} element={<BlogFive />} />
      <Route exact path={`/blog/${blogSlugs[6]}`} element={<BlogSix />} />
      <Route exact path={`/blog/${blogSlugs[7]}`} element={<BlogSeven />} />
      <Route exact path={`/blog/${blogSlugs[8]}`} element={<BlogEighth />} />
      <Route exact path={`/blog/${blogSlugs[9]}`} element={<BlogNine />} />
      <Route exact path={`/blog/${blogSlugs[10]}`} element={<BlogTen />} />
      <Route exact path={`/blog/${blogSlugs[11]}`} element={<BlogEleven />} />
    </Routes>
    </>
  );
};

export default MainRoutes;