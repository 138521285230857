// --- Third Party Libraries ---
import React, { useState, useMemo, useRef, useLayoutEffect, useEffect } from "react";
import { Typography, Box, Tabs, Tab, Grid, Card } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { agentsImages } from "../../utils/imageUtils";

// --- GSAP Setup ---
gsap.registerPlugin(ScrollTrigger);

// --- Control Component ---
const Control = () => {
    // --- State Hooks ---
    const [controlTab, setControlTab] = useState(0);
    const [activePhase, setActivePhase] = useState(0);
    const isMobile = useMediaQuery("(max-width:768px)");
    const containerRef = useRef(null);
    const rightContentRef = useRef(null);
    const { t } = useTranslation();
    const controlCounterValues = [50134, 1850];

    // --- Tab Data ---
    const phaseTabs = useMemo(() => [
        {
            title: t("AGENTS_PAGE.CONTROL.PHASES.1.TITLE"),
            contentTitle: t("AGENTS_PAGE.CONTROL.PHASES.1.CONTENT_TITLE"),
            img: agentsImages.sand_clock,
            contentDescription1: t("AGENTS_PAGE.CONTROL.PHASES.1.contentDescription1"),
            contentDescription2: t("AGENTS_PAGE.CONTROL.PHASES.1.contentDescription2"),
            contentDescription3: t("AGENTS_PAGE.CONTROL.PHASES.1.contentDescription3"),
            contentDescription4: t("AGENTS_PAGE.CONTROL.PHASES.1.contentDescription4"),
            contentDescription5: t("AGENTS_PAGE.CONTROL.PHASES.1.contentDescription5"),
            contentDescription6: t("AGENTS_PAGE.CONTROL.PHASES.1.contentDescription6"),
        },
        {
            title: t("AGENTS_PAGE.CONTROL.PHASES.2.TITLE"),
            contentTitle: t("AGENTS_PAGE.CONTROL.PHASES.2.CONTENT_TITLE"),
            img: agentsImages.agents_img_5,
            contentDescription1: t("AGENTS_PAGE.CONTROL.PHASES.2.contentDescription1"),
            contentDescription2: t("AGENTS_PAGE.CONTROL.PHASES.2.contentDescription2"),
            contentDescription3: t("AGENTS_PAGE.CONTROL.PHASES.2.contentDescription3"),
            contentDescription4: t("AGENTS_PAGE.CONTROL.PHASES.2.contentDescription4"),
            contentDescription5: t("AGENTS_PAGE.CONTROL.PHASES.2.contentDescription5"),
            contentDescription6: t("AGENTS_PAGE.CONTROL.PHASES.2.contentDescription6"),
        },
        {
            title: t("AGENTS_PAGE.CONTROL.PHASES.3.TITLE"),
            contentTitle: t("AGENTS_PAGE.CONTROL.PHASES.3.CONTENT_TITLE"),
            img: agentsImages.agents_img_5,
            contentDescription1: t("AGENTS_PAGE.CONTROL.PHASES.3.contentDescription1"),
            contentDescription2: t("AGENTS_PAGE.CONTROL.PHASES.3.contentDescription2"),
            contentDescription3: t("AGENTS_PAGE.CONTROL.PHASES.3.contentDescription3"),
            contentDescription4: t("AGENTS_PAGE.CONTROL.PHASES.3.contentDescription4"),
            contentDescription5: t("AGENTS_PAGE.CONTROL.PHASES.3.contentDescription5"),
        },
        {
            title: t("AGENTS_PAGE.CONTROL.PHASES.4.TITLE"),
            contentTitle: t("AGENTS_PAGE.CONTROL.PHASES.4.CONTENT_TITLE"),
            img: agentsImages.agents_img_5,
            contentDescription1: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription1"),
            contentDescription2: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription2"),
            contentDescription3: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription3"),
            contentDescription4: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription4"),
            contentDescription5: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription5"),
            contentDescription6: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription6"),
            contentDescription7: t("AGENTS_PAGE.CONTROL.PHASES.4.contentDescription7"),
        }
    ], [t]);

    // --- Animations ---
    useGSAP(() => {
        if (isMobile) {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            return;
        }
        gsap.to(containerRef.current, {
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top top",
                end: "+=3000",
                scrub: true,
                pin: true,
                anticipatePin: 1,
                onUpdate: (self) => {
                    const scrollProgress = self.progress.toFixed(3);
                    const phaseIndex = Math.floor(scrollProgress * phaseTabs.length);
                    setActivePhase(Math.min(phaseTabs.length - 1, phaseIndex));
                },
            },
        });
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, [phaseTabs.length, isMobile]);

    useLayoutEffect(() => {
        if (isMobile || !rightContentRef.current) return;
        const ctx = gsap.context(() => {
            gsap.fromTo(
                rightContentRef.current,
                { autoAlpha: 0, y: 30 },
                {
                    autoAlpha: 1,
                    y: 0,
                    duration: 0.6,
                    ease: "power2.out",
                }
            );
        }, rightContentRef);
        return () => ctx.revert();
    }, [activePhase, isMobile]);

    useEffect(() => {
        ScrollTrigger.normalizeScroll(true);
        ScrollTrigger.refresh();
    }, []);

    const renderCounter = () => (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderBottom: "1px solid",
                    width: "100%",
                    borderImageSource:
                        "linear-gradient(90deg, rgba(215, 219, 236, 0) 0%, #D7DBEC 49%, rgba(215, 219, 236, 0) 100%)",
                    borderImageSlice: 1,
                }}
            >
                <Tabs
                    value={controlTab}
                    onChange={(_e, newValue) => setControlTab(newValue)}
                    centered
                    TabIndicatorProps={{
                        sx: {
                            height: "1px",
                            background: "rgba(255, 255, 255, 1)",
                        },
                    }}
                    sx={{
                        "& .MuiTab-root": {
                            textTransform: "none",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 0.8)",
                            "&.Mui-selected": {
                                color: "rgba(255, 255, 255, 1)",
                                fontWeight: 700,
                            },
                        },
                    }}
                >
                    <Tab label={isMobile ? t("AGENTS_PAGE.CONTROL.TABS.SUPPORT_MOBILE") : t("AGENTS_PAGE.CONTROL.TABS.SUPPORT")} />
                    <Tab label={isMobile ? t("AGENTS_PAGE.CONTROL.TABS.SALES_MOBILE") : t("AGENTS_PAGE.CONTROL.TABS.SALES")} />
                </Tabs>
            </Box>
            <Typography variant="h2" className="control-counter">
                {controlCounterValues[controlTab].toLocaleString()}
            </Typography>
        </>
    );

    // --- Render Phases Content (Mobile)
    const renderPhasesMobile = () => (
        <>
            <Box
                sx={{
                    textAlign: "left",
                    width: "100%",
                    borderBottom: "1px solid rgba(204, 210, 231, 1)",
                    overflowX: "auto",
                }}
            >
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={activePhase}
                    onChange={(_e, val) => setActivePhase(val)}
                    TabIndicatorProps={{
                        sx: {
                            height: "2px",
                            borderRadius: "2px",
                            background: "rgba(255, 255, 255, 1)",
                        },
                    }}
                    sx={{
                        minWidth: "max-content",
                        "& .MuiTabs-flexContainer": {
                            gap: "8px",
                        },
                        "& .MuiTab-root": {
                            textTransform: "none",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 0.8)",
                            minWidth: "fit-content",
                            paddingX: 2,
                            "&.Mui-selected": {
                                color: "rgba(255, 255, 255, 1)",
                                fontWeight: 700,
                            },
                        },
                    }}
                >
                    {phaseTabs.map((tab, index) => {
                        const phaseNumber = tab.title.split(":")[0].trim();
                        return (
                            <Tab key={index} label={phaseNumber} />
                        );
                    })}
                </Tabs>
            </Box>
            <Box className="control-main-content-right-grid-container">
                <Typography
                    variant="subtitle2"
                    className="control-main-content-right-title"
                >
                    {phaseTabs[activePhase].contentTitle}
                </Typography>
                {Object.keys(phaseTabs[activePhase])
                    .filter(key => typeof key?.startsWith === "function" && key.startsWith("contentDescription"))
                    .map((key, idx) => {
                        const rawLine = phaseTabs[activePhase][key];
                        const isBold = typeof rawLine?.startsWith === "function" && rawLine.startsWith("**");

                        if (isBold) {
                            const cleanedLine = rawLine.replace(/\*\*/g, "");
                            const [boldPart, normalPart] = cleanedLine.split(":");
                            return (
                                <Typography key={idx} variant="body2" sx={{ lineHeight: 1.6, mb: 1 }}>
                                    <span style={{ fontWeight: 700, fontSize: isMobile ? "16px" : "14px", color: "#FFFFFF" }}>
                                        {boldPart}:
                                    </span>
                                    <span style={{ fontWeight: 400, fontSize: isMobile ? "16px" : "14px", color: "#B0B3BB" }}>
                                        {normalPart}
                                    </span>
                                </Typography>
                            );
                        }
                        return (
                            <Typography key={idx} variant="body2" sx={{ color: "#B0B3BB", fontSize: isMobile ? "16px" : "14px", lineHeight: 1.6 }}>
                                {rawLine}
                            </Typography>
                        );
                    })}
            </Box>
        </>
    )

    // --- Render Phases Content
    const renderPhases = () => (
        <Grid container spacing={4} className="control-main-content-grid-container">
            <Grid item xs={12} md={4}>
                <Tabs
                    orientation="vertical"
                    value={activePhase}
                    onChange={(_e, val) => setActivePhase(val)}
                    TabIndicatorProps={{ style: { display: "none" } }}
                    sx={{
                        "& .MuiTab-root": {
                            alignItems: "flex-start",
                            textTransform: "none",
                            padding: "0",
                            minHeight: "auto",
                            justifyContent: "flex-start",
                        },
                    }}
                >
                    {phaseTabs.map((phase, index) => (
                        <Tab key={index} label={
                            <Box
                                sx={{
                                    textAlign: "left",
                                    width: "100%",
                                    borderBottom: "1px solid",
                                    borderImageSource: "linear-gradient(90deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0) 100%)",
                                    borderImageSlice: 1,
                                    minHeight: "61px"
                                }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        color: activePhase === index
                                            ? "white"
                                            : "rgba(255, 255, 255, 0.72)",
                                        borderTop: activePhase === index
                                            ? "2px solid rgba(255, 255, 255, 1)"
                                            : "2px solid transparent",
                                        paddingTop: "8px",
                                        display: "inline-block",
                                    }}
                                >
                                    {phase.title}
                                </Typography>
                            </Box>
                        } />
                    ))}
                </Tabs>
            </Grid>
            <Grid item xs={12} md={4} className="control-main-content-img-grid-container">
                <img
                    // src={activeAgent.img}
                    alt={activePhase.title}
                    className="control-main-content-img"
                />
            </Grid>
            <Grid item xs={12} md={4} className="control-main-content-right-grid-container" ref={rightContentRef}>
                <Card className="phases-card">
                    <Typography
                        variant="subtitle2"
                        className="control-main-content-right-title"
                    >
                        {phaseTabs[activePhase].contentTitle}
                    </Typography>
                    {Object.keys(phaseTabs[activePhase])
                        .filter(key => typeof key?.startsWith === "function" && key.startsWith("contentDescription"))
                        .map((key, idx) => {
                            const rawLine = phaseTabs[activePhase][key];
                            const isBold = typeof rawLine?.startsWith === "function" && rawLine.startsWith("**");

                            if (isBold) {
                                const cleanedLine = rawLine.replace(/\*\*/g, "");
                                const [boldPart, normalPart] = cleanedLine.split(":");
                                return (
                                    <Typography key={idx} variant="body2" sx={{ lineHeight: 1.6, mb: 1 }}>
                                        <span style={{ fontWeight: 700, marginRight: 4, color: "white" }}>•</span>
                                        <span style={{ fontWeight: 700, color: "white" }}>
                                            {boldPart}:
                                        </span>
                                        <span style={{ fontWeight: 300, color: "white" }}>
                                            {normalPart}
                                        </span>
                                    </Typography>
                                );
                            }
                            return (
                                <Typography key={idx} variant="body2" sx={{ color: "white", fontWeight: 300, lineHeight: 1.6 }}>
                                    {rawLine}
                                </Typography>
                            );
                        })}
                </Card>
            </Grid>
        </Grid>
    )

    // --- Render Component ---
    return (
        <Box>
            <Box className="control-component">
                {renderCounter()}
            </Box>
            <Box className="control-main-content" ref={containerRef}>
                <Typography variant="h3" className="control-main-content-title">
                    {t("AGENTS_PAGE.CONTROL.TITLE")}
                </Typography>
                <Typography variant="subtitle1" className="control-main-content-subtitle">
                    {t("AGENTS_PAGE.CONTROL.SUBTITLE")}
                </Typography>
                {isMobile ? (
                    renderPhasesMobile()
                ) : (
                    renderPhases()
                )}
            </Box>
        </Box>
    );
};

export default Control;