import { createTheme } from "@mui/material/styles";

export const DefaultTheme = createTheme({
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: "contained" },
					style: {
						"border-radius": "16px",
						color: "white",
						fontSize: "16px",
						fontWeight: "600",
						textTransform: "none",
						background: "#6257FF",
						padding: "12px 20px 12px 20px",
						width: "fit-content",
						"&:hover": {
							background: "#6257FF", 
						},
					},
				},
				{
					props: { variant: "outlined", color: "primary" },
					style: {
						borderColor: "#6257FF",
						"border-radius": "16px",
						textTransform: "none",
						fontWeight: "600",
						"font-size": "16px",
						"color": "#6257FF",
						padding: "12px 20px 12px 20px",
					},
				},
				{
					props: { variant: "text", color: "primary" },
					style: {
						color: "#6257FF",
						"font-size": "16px",
						"font-style": "normal",
						fontSize: "16px",
						fontWeight: "600",
						textTransform: "none",
						padding: "12px 20px 12px 20px",
					},
				},
			],
		},
	},
});
