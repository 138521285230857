import React, { useRef } from "react";
import { Box, Typography, Card, CardMedia, CardContent, Button, Grid, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";

// --- Image Utilities ---
import { blogHeaderImages } from "../../utils/imageUtils";

// --- Blog Slugs Mapping ---
const blogSlugs = {
    1: "ai-sales",
    2: "ai-customer-service-2025",
    3: "chatgpt-vs-gemini",
    4: "intercom-alternatives",
    5: "best-customer-service-automation-software",
    6: "freshworks-alternative",
    7: "zendesk-alternative",
    8: "tidio-alternative",
    9: "helpcrunch-alternatives",
    10: "liveperson-competitors",
    11: "chatbot-alternatives"
};

gsap.registerPlugin(ScrollTrigger);

// --- Blog Component ---
const Blog = (isLiveChatPage = false) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const buttonRef = useRef(null);

    // Articles array
    const articles = useMemo(
        () => t("BLOG_PAGE.ARTICLES", { returnObjects: true }),
        [t]
    );

    const latestThreeArticles = useMemo(() => {
        return Object.entries(articles)
            .sort(([a], [b]) => {
                const aNum = parseInt(a.replace("ARTICLE_", ""));
                const bNum = parseInt(b.replace("ARTICLE_", ""));
                return bNum - aNum; // Descending
            })
            .slice(0, 3);
    }, [articles]);

    useGSAP(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            toggleActions: "play none none none"
          },
        });
      
        tl.from(titleRef.current, {
          opacity: 0,
          y: 30,
          duration: 0.3,
          ease: "power2.out"
        })
          .from(subtitleRef.current, {
            opacity: 0,
            y: 20,
            duration: 0.3,
            ease: "power2.out"
          })
          .from(cardsRef.current, {
            opacity: 0,
            y: 30,
            duration: 0.5,
            stagger: 0.2,
            ease: "power1.out",
          })
          .from(buttonRef.current, {
            opacity: 0,
            y: 40,
            duration: 0.3,
            ease: "power2.out"
        })
      });
      
    return (
        <Box className={`blog-section ${isLiveChatPage ? "blog-section-live-chat-background" : ""}`} ref={sectionRef}>
            <Typography variant="h3" ref={titleRef}>
                {t("BLOG_PAGE.MAIN_TITLE")}
            </Typography>
            <Typography variant="subtitle1" ref={subtitleRef}>
                {t("BLOG_PAGE.SUBTITLE")}
            </Typography>
            <Grid container spacing={4}>
                {latestThreeArticles.map(([id, article], index) => {
                    const numericId = id.replace("ARTICLE_", "");
                    return (
                        <Grid item xs={12} sm={6} md={4} key={id} ref={(el) => (cardsRef.current[index] = el)}>
                            <motion.div className="blog-card" onClick={() => navigate(`/blog/${blogSlugs[numericId]}`)} whileHover={{ scale: 1.05 }}>
                                <CardMedia
                                    component="img"
                                    src={blogHeaderImages[article.CARD_MEDIA]}
                                    alt={article.TITLE}
                                />
                                <CardContent className="blog-content">
                                    <Chip label={article.TYPE} />
                                    <Typography variant="subtitle1">{article.TITLE}</Typography>
                                    <Typography variant="body1">{article.SUBTITLE}</Typography>
                                    <Typography variant="caption">{article.DATE}</Typography>
                                </CardContent>
                            </motion.div>
                        </Grid>
                    );
                })}
            </Grid>
            <Button
                ref={buttonRef}
                variant="contained"
                onClick={() => {
                    navigate("/blog")
                }}>
                {t("BLOG_PAGE.BLOG_BUTTON")}
            </Button>
        </Box>
    );
};

export default Blog;
